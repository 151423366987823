enum ErpStatus {
  NEW = 'new',
  READY = 'ready',
  DONE = 'done',
  FAILURE = 'failed',
  PROCESSING = 'processing',
  NEED = 'need',
  VOIDED = 'voided'
}

enum FlowStatus {
  UNASSIGNED = 'unassigned',
  APPROVED = 'approved',
  PENDING = 'pending',
  DISPUTED = 'disputed',
  PAID = 'paid',
  UNPAID = 'unpaid',
  CANCELLED = 'cancelled',
  NEW = 'new'
}

enum ProcoreStatus {
  NEW = 'new',
  READY = 'ready',
  DONE = 'done',
  FAILURE = 'failed',
  PROCESSING = 'processing'
}

enum ERPs {
  NONE = 'none',
  QB = 'QuickBooks',
  QBD = 'QuickBooksDesktop',
  PROCORE = 'Procore',
  SAGE = 'Sage'
}

enum OrdersFetchDataMethod {
  Entire = 'entire',
  Pdf = 'pdf',
  LineItem = 'lineItem'
}

export { ErpStatus, FlowStatus, ProcoreStatus, ERPs, OrdersFetchDataMethod };
