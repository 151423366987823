export const ALLOWED_THIRD_APP = [
  'development',
  'staging',
  'staging-porter',
  'production',
  'sage',
  'biz',
  'test-1',
  'test-2',
  'test-3'
].includes(import.meta.env.VITE_ENV);
